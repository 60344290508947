.hamburger {
	@include respondToDesktop() {
		display: none;
		.logo {
			display: none;
		}
	}
	.logo {
		width: 100%;
		display: flex;
		align-items: center;
		margin-left: 25px;
	}
	input {
		display: none;
	}
	display: flex;
	justify-content: center;
	justify-content: flex-end;
	width: 100vw;
	height: 100px;
	z-index: 9999;
	position: fixed;

	> * {
		position: relative;
	}

	label {
		display: block;
		cursor: pointer;
		width: 25vw;
		height: 25vw;
		max-width: 125px;
		max-height: 125px;
		position: absolute;
		top: 0;
		right: 0;
	}

	@media (min-aspect-ratio: 1/1) {
		label {
			width: 50vh;
			height: 50vh;
		}
	}

	path {
		fill: none;
		stroke: #575757;
		stroke-width: 3;
		stroke-linecap: round;
		stroke-linejoin: round;
		--length: 24;
		--offset: -38;
		stroke-dasharray: var(--length) var(--total-length);
		stroke-dashoffset: var(--offset);
		transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
	}

	circle {
		fill: rgb(255, 255, 255);
		// opacity: 0;
	}

	label:hover circle {
		opacity: 1;
	}

	.cross input:checked + svg {
		.line--1,
		.line--3 {
			--length: 22.627416998;
		}
		.line--2 {
			--length: 0;
		}
	}

	.back input:checked + svg {
		.line--1,
		.line--3 {
			--length: 8.602325267;
		}
	}

	.menu--1 {
		background-color: #3f77e9;

		.line--1,
		.line--3 {
			--total-length: 126.64183044433594;
		}
		.line--2 {
			--total-length: 70;
		}
		input:checked + svg {
			.line--1,
			.line--3 {
				--offset: -94.1149185097;
			}
			.line--2 {
				--offset: -50;
			}
		}
	}
}
