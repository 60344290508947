$cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.85);
  perspective: 1000px;
  overflow: hidden;
  overflow-y: auto;
  .modal_content {
    animation: flip_horz 0.5s $cubic;
    max-width: 90%;
    max-height: 90%;
    background: #fff;
    display: flex;
    min-width: 650px;
    flex-direction: column;
   
    @include respondToBelow(sm) {
      min-width: 0;
      width: 90%;
    }

    &.alert {
      animation: shake 0.5s;
    }
    > div {
      .modal_head {
        background:$color;
        color: #fff;
        font-size: 1.25rem;
        height: 50px;
        display: flex;
        align-items: center;
        padding-left: 15px;
        padding-right: 50px;
        position: relative;
        .heading-ttl {
          font-size: 25px;
          color: #fff;
          margin: 0;
        }
        .close-x {
          position: absolute;
          right: 0;
          top: 0;
          height: 40px;
          width: 40px;
          bottom: 0;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.5rem;
        }
      }
      .modal_body {
        font-size: 0.9rem;
        line-height: 1.5;
        flex-grow: 1;
        overflow: auto;

        .response {
          p {
            &:first-letter {
              padding-left: 25px;
            }
          }
        }
      }
      .modal_footer {
        padding: 15px 0 0;
      }
    }
  }
}
@keyframes flip_horz {
  0% {
    transform: rotateX(-90deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes shake {
  0% {
    -webkit-transform: translate(30px);
  }
  20% {
    -webkit-transform: translate(-30px);
  }
  40% {
    -webkit-transform: translate(15px);
  }
  60% {
    -webkit-transform: translate(-15px);
  }
  80% {
    -webkit-transform: translate(8px);
  }
  100% {
    -webkit-transform: translate(0px);
  }
}
.ico_large {
  height: 75px;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  border-radius: 50%;
  margin: 25px auto;
}
