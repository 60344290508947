.navigation {
	@include respondToAbove(md) {
		display: none;
	}
	z-index: 9998;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	position: fixed;
	backdrop-filter: blur(10px);
	padding: 50px 30px 20px 30px;
	transition: all 0.15s ease;
	background: radial-gradient(
			400px 400px at 50% 400px,
			rgba(0, 215, 255, 0.4) 0,
			#00355a63 100%
		),
		#002844cc;
	background-position: center, top center;
	background-size: cover, contain;
	animation: 0.3s ease-out opacityAnim;
	.img-wrapper {
		// border: 1px solid red;
		position: absolute;
		height: 59px;
		display: flex;
		width: 100px;
		height: 60px;
		left: 0;
		top: 25px;

		svg {
			width: 100%;
			height: 100%;
			path {
				fill: white;
			}
		}
	}
	.list-items-container {
		font-size: 27px;
		font-weight: bold;
		color: white;
		margin-top: 30px;
		will-change: transform;
		backface-visibility: hidden;
		animation: 0.3s ease 0.4s alternate both running translateFromRight;
		ul {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 50px;

			li {
				padding: 20px 0;
			}
		}
	}
	.primary_action {
		// border: 1px solid red;
		animation: 0.3s ease 0.4s alternate both running translateFromRight;
		color: white;
		border: none;
		.login_container {
			display: flex;
			flex-direction: column;
			a {
				text-decoration: none;
				@include center;

				justify-content: space-between;
				padding: 12px 25px;
				+ a {
					margin-top: 25px;
				}
				@extend %btn;
			}
		}

		.action-btn {
			span {
				&:last-of-type {
					margin-top: 6px;
					margin-left: 5px;
				}
			}
		}
	}

	.download-btn-container {
		display: flex;
		animation: 0.3s ease 0.4s alternate both running translateFromRight;

		ul,
		li {
			display: flex;
			margin: 0px !important;
		}

		.btn-container {
			display: flex;
			width: 100%;
			flex-direction: column;
			justify-content: space-between;
			min-height: 130px;
			.action-btn {
				border: 2px solid white;
				padding: 12px 25px;
				font-size: 18px;
				display: flex;
				border-radius: 15px;
				box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.1);

				a {
					text-decoration: none;
					color: white;
					display: flex;
					justify-content: space-between;
					width: 100%;

					span {
						&:first-of-type {
							// margin-top: 6px;
							margin-right: 10px;
						}
					}
				}
			}
		}
	}
}

@keyframes opacityAnim {
	0% {
		opacity: 0;
		visibility: hidden;
	}

	100% {
		opacity: 1;
		visibility: visible;
	}
}

@keyframes translateFromRight {
	0% {
		opacity: 0;
		transform: scale(0);
		// transform: translateX(100%) translateZ(0);
	}

	100% {
		opacity: 1;
		transform: scale(1);
		// transform: translateX(0) translateZ(0);
	}
}
