ul.steps {
	position: relative;
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	margin-left: 30px;
	// align-items: center;
	// justify-content: center;
	li {
		padding: 10px;
		display: flex;
		align-items: center;
		margin: 20px 40px;
		min-height: 50px;
		position: relative;
		border-radius: 10px;
		line-height: 22px;

		&:before {
			content: "";
			position: absolute;
			background-color: red;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 60px;
			width: 60px;
			border-radius: 50%;
			left: -68px;
			top: 0px;
		}
		&:nth-of-type(1) {
			color: rgb(215, 92, 92);
			background-color: #f6dcdd;
			&:before {
				content: url(https://icon.now.sh/cloud_download/28/fff);
				background-color: rgb(215, 92, 92);
			}
		}

		&:nth-of-type(2) {
			color: rgb(158, 92, 215);
			background-color: #e9d5f7;
			&:before {
				content: url(https://icon.now.sh/lightbulb_outline/28/fff);
				background-color: rgb(158, 92, 215);
			}
		}

		&:nth-of-type(3) {
			color: rgb(107, 92, 215);
			background-color: #e1d6f7;
			&:before {
				content: url(https://icon.now.sh/play_circle_filled/28/fff);
				background-color: rgb(107, 92, 215);
			}
		}

		&:nth-of-type(4) {
			color: rgb(92, 140, 215);
			background-color: #dfe7f7;
			&:before {
				content: url(https://icon.now.sh/trending_up/28/fff);
				background-color: rgb(92, 140, 215);
			}
		}

		&:nth-of-type(5) {
			color: #3b9c51;
			background-color: #d4f5e4;
			&:before {
				content: url(https://icon.now.sh/share/28/fff);
				background-color: rgb(92, 215, 120);
			}
		}
	}
	&:before {
		content: "";
		width: 3px;
		height: 85%;
		background: linear-gradient(
			180deg,
			#d75c5c 0%,
			#9e5cd7 23.96%,
			#6b5cd7 48.44%,
			#5c8cd7 75%,
			#5cd75c 100%
		);
		position: absolute;
		left: 0;
		top: 22px;
	}
}
