$btn-gradient: linear-gradient(270deg, #f48508 0%, #ff9900 100%);
$btn-rad: 10px;
$color: #004c77;
// Extra small screen / phone
$screen-xs-min: 400px;

// Small screen / tablet
$screen-sm-min: 768px;

// Medium screen / desktop
$screen-md-min: 992px;

// Large screen / wide desktop
$screen-lg-min: 1200px;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

$max-width: 1200px;

$black: rgb(187, 0, 0);

%btn {
	cursor: pointer;
	border: none;
	color: white;
	background: $btn-gradient;
	box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.1);
	border-radius: $btn-rad;
}
