@mixin respondTo($media) {
	@if $media== "xs" {
		@media only screen and (max-width: $screen-xs-max) {
			@content;
		}
	} @else if $media== "sm" {
		@media only screen and (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
			@content;
		}
	} @else if $media== "md" {
		@media only screen and (max-width: $screen-md-max) and (min-width: $screen-md-min) {
			@content;
		}
	} @else if $media== "lg" {
		@media only screen and (min-width: $screen-lg-min) {
			@content;
		}
	}
}

@mixin respondToAbove($media) {
	@if $media== "xs" {
		@media only screen and (min-width: $screen-xs-min) {
			@content;
		}
	} @else if $media== "sm" {
		@media only screen and (min-width: $screen-sm-min) {
			@content;
		}
	} @else if $media== "md" {
		@media only screen and (min-width: $screen-md-min) {
			@content;
		}
	} @else if $media== "lg" {
		@media only screen and (min-width: $screen-lg-min) {
			@content;
		}
	}
}

@mixin respondToBelow($media) {
	@if $media== "xs" {
		@media only screen and (max-width: $screen-xs-min) {
			@content;
		}
	} @else if $media== "sm" {
		@media only screen and (max-width: $screen-sm-max) {
			@content;
		}
	} @else if $media== "md" {
		@media only screen and (max-width: $screen-md-max) {
			@content;
		}
	} @else if $media== "lg" {
		@media only screen {
			@content;
		}
	}
}

@mixin respondToTablet {
	@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
		@content;
	}
}

@mixin respondToSmall-n-Medium {
	@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
		@content;
	}
}

@mixin respondToTm {
	@media (min-width: $screen-md-min) and (max-width: $screen-lg-min) {
		@content;
	}
}

@mixin respondToMobile() {
	@include respondTo(xs) {
		@content;
	}
}

@mixin respondToDesktop() {
	@include respondToAbove(md) {
		@content;
	}
}

@mixin center {
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Calculate fluid ratio based on two dimensions (width/height) */
@mixin fluid-ratio($large-size, $small-size) {
	$width-large: nth($large-size, 1);
	$width-small: nth($small-size, 1);
	$height-large: nth($large-size, 2);
	$height-small: nth($small-size, 2);
	$slope: ($height-large - $height-small) / ($width-large - $width-small);
	$height: $height-small - $width-small * $slope;

	padding-top: $slope * 100%;
	height: $height;

	background-size: cover;
	-moz-background-size: cover; /* Firefox 3.6 */
	background-position: center; /* Internet Explorer 7/8 */
}

@mixin bg($url) {
	background-image: $url;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	-moz-background-size: cover; /* Firefox 3.6 */
	background-size: cover;
	background-position: center; /* Internet Explorer 7/8 */
}
