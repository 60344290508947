$slider_height: 500px;
$login_primary: #fff;

$width: 400px;
$height: 450px;
#slider {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	line-height: 24px;
	width: 100%;
	.slick-slider {
		text-align: center;
		width: 100%;
		height: $height;
		height: 100%;
		@include respondToBelow(sm) {
		font-size: 14px;	
		}
		.slick-list {
			margin: 0 auto;
		}
		.slick-slide {
			border-radius: 8px;
			color: black;
			> div {
				height: $height;
				width: $width;
				@include respondToBelow(sm) {
				height: auto;
				width: auto;
				margin: 10px;	
				box-shadow: none;
				padding: 20px;

				}
				padding: 30px;
				margin: 50px;
				background: $login_primary;
				background: #ffffff;
				box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
				border-radius: 8px;
			}
		}
		.slick-dots {
			@include respondToBelow(sm) {
				bottom: -34px
			}
			li {
				button {
					&::before {
						content: "";
						position: absolute;
						width: 10px;
						height: 10px;
						top: 7px;
						background: white;
						border-radius: 30px;
						transition: all 0.5s ease-out;
						z-index: 1;
					}
				}
			}
			position: absolute;
			bottom: 0px;
			display: flex !important;
			align-items: center;
			justify-content: center;
			list-style-type: none;
			padding: 0;
			margin-bottom: 0;
			margin-top: 25px;
			.slick-active {
				button {
					&::before {
						content: "";
						position: absolute;
						width: 10px;
						height: 10px;
						top: 7px;
						background: #31769d;
						border-radius: 100px !important;
					}
				}
			}
		}

		.slick-arrow {
			@include center;
			@include respondToBelow(sm) {
				display: none !important;
			}
			z-index: 1;
			width: 60px;
			height: 60px;
			border-radius: 50px;
			background: #ffffff;
			box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.1);
			&:before {
				color: #31769d;
			}
		}
		.slick-prev {
			left: -80px;
			
			position: absolute;
			&:before {
				content: url("../../images/arr-left.svg");
			}
		}

		.slick-next {
			right: -80px;
			
			&:before {
				content: url("../../images/arr-right.svg");
			}
		}
	}

	.card {
		.quote-icon {
			width: 50%;
		}
		display: flex !important;
		flex-direction: column;
		text-align: left;
		height: 100%;
		width: 100%;
		color: #31769d;
		.profile-container {
			margin-top: 20px;
			@include center;
			.profile {
				@include respondToBelow(sm) {
					flex-direction: column;
					align-items: center;
					justify-content: center;
					padding: 10px;
				}
				padding: 20px;
				width: 100%;
				display: flex;
				background: #ebf4fa;
				border-radius: 8px;
				align-items: center;
				justify-content: space-evenly;
				.details {
					@include respondToBelow(sm) {
						font-size: 14px;
						text-align: center;
						margin-top: 15px;
					}
					.name {
						font-weight: bold;
					}

					.desgnation {
						font-size: 12px;
					}
				}
				.pic {
					width: 50px;
					height: 50px;

					> div {
						width: 100%;
						height: 100%;
						border: 4px solid #ffffff;
						box-shadow: 0px 1px 40px rgba(49, 118, 157, 0.1),
							1px 0px 40px rgba(49, 118, 157, 0.1);
						border-radius: 50%;
					}

					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}
