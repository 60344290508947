%titlecontainer {
	.title-container {
		.title {
			font-weight: bold;
		}

		.para {
			@include respondToBelow(sm) {
				width: 100%;
				font-size: 16px;
			}
			font-size: 18px;
			margin-top: 10px;
			width: 70%;
			line-height: 24px;
		}
	}
}

#home {
	position: relative;
	.nav-container {
		// height: 180px;
		height: 70px;
		box-shadow: 0 16px 26px -10px rgba(0, 0, 0, 0.06),
			0 4px 25px 0 rgba(0, 0, 0, 0.12);
		background: hsla(0, 0%, 100%, 0.9);
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
		display: flex;
		top: 0;
		left: 0;
		right: 0;
		position: fixed;
		z-index: 9999;
		transition: none;
		@include respondToBelow(sm) {
			display: none;
		}

		.navbar {
			@include bg(url("../../images/navbar.svg"));
		}

		.nav {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 70px;
			position: absolute;

			&-content {
				position: absolute;
				display: flex;
				width: 90%;
				justify-content: space-between;
				align-items: center;
				.logo {
					height: 40px;
					width: 120px;
					> div {
						@include bg(url("../../images/logo.svg"));
					}
				}

				ul {
					display: flex;
					align-items: center;
					font-size: 16px;
					li {
						&:hover {
							cursor: pointer;
						}
						+ li {
							margin-left: 20px;
						}

						a {
							@extend %btn;
							@include center;
							text-decoration: none;
							padding: 5px 12px;
							svg {
								margin-top: 10px;
								margin-left: 10px;
							}
						}
					}
				}
			}
		}
	}
	.section1 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		color: $color;
		height: 100vh;
		width: 100%;
		background: linear-gradient(
				238.02deg,
				#055dd4 -20.66%,
				rgba(5, 93, 212, 0) 16.03%
			),
			linear-gradient(270deg, #007aa1 -1.81%, rgba(255, 255, 255, 0) 100%),
			linear-gradient(218.66deg, #009cf3 0%, rgba(0, 137, 215, 0) 71.93%),
			linear-gradient(
				313.27deg,
				rgba(255, 255, 255, 0) -102.94%,
				#00f0ff 12.93%,
				rgba(255, 255, 255, 0) 40.64%
			),
			linear-gradient(
				161.68deg,
				#0086b1 10.15%,
				#32a4ef 38.86%,
				#36b8f6 67.44%,
				#ffffff 67.45%,
				#ffffff 82.68%
			);
		top: 0;
		left: 0;
		right: 0;
		z-index: 1;
		.cover {
			@include respondToAbove(lg) {
				height: 100vh;
			}
		}

		.main-content-con {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 80%;
			position: relative;
			height: 100%;
			z-index: 9;
			text-align: justify;
			top: 60px;
			right: 85px;

			@include respondToBelow(sm) {
				width: 100%;
				right: 0;
				flex-direction: column-reverse;
				margin-top: 100px;
			}
			> .iphone {
				@include respondToBelow(sm) {
					width: 100%;
					height: 70vh;
				}
				width: 40%;
				height: 80vh;
				div {
					height: 100%;
					@include bg(url("../../images/IPHONE.png"));
					background-size: auto 90%;
					@include respondToBelow(sm) {
						// background-position: center center;
						background-size: auto 75%;
						background-position: center 10%;
					}

					@include respondToBelow(xs) {
						background-size: auto 80%;
						background-position: center 8%;
					}
					background-position: center right;
				}
			}

			.content-container {
				text-align: justify;
				@include respondToBelow(sm) {
					width: auto;
					padding: 20px;
					margin-top: 80px;
					// width: 90%;
				}

				@include respondToBelow(xs) {
					margin-top: 100px;
				}
				color: white;
				width: 60%;
				display: flex;
				justify-content: center;
				flex-direction: column;
				font-size: 22px;
				height: 100%;
				text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);

				.title {
					font-size: 50px;
					@include respondToBelow(sm) {
						font-size: 40px;
					}

					@include respondToBelow(xs) {
						font-size: 24px;
					}
					font-weight: bold;
				}
				.content {
					font-size: 18px;

					line-height: 140%;
					margin-top: 10px;
					@include respondToBelow(xs) {
						font-size: 16px;
					}
				}

				.icons {
					@include respondToBelow(xs) {
						height: auto;
					}
					display: flex;
					align-items: center;
					height: 44px;
					width: 100%;
					margin: 0;
					margin-top: 15px;
					.play {
						margin-left: 10px;
						@include respondToBelow(xs) {
							padding: 10px 20px;
							font-size: 14px;
						}
					}

					.download_btn {
						@include respondToBelow(xs) {
							font-size: 14px;
						}
					}
				}
			}
		}

		.arrow-container {
			width: 100%;
			@include center;
			.arrow-down {
				@include respondToBelow(sm) {
					display: none;
				}
				background: #ffffff;
				box-shadow: 0px 3px 18.75px rgba(0, 0, 0, 0.1);
				width: 60px;
				height: 60px;
				@include center;
				border-radius: 50px;
				color: #319bde;
				position: absolute;
				bottom: 40px;
				z-index: 9;
			}
		}
	}

	.section2 {
		position: relative;
		height: 800px;
		background-color: #ecf5fb;
		@include respondToBelow(sm) {
			height: 900px;
		}
		@include center;
		.doodle {
			@include bg(url("../../images/doodles.svg"));
			height: 100%;
			width: 100%;
			background-size: auto 100%;
			background-position: center center;
		}

		.hiw {
			@include respondToBelow(sm) {
				flex-direction: column;
				// top: none;
				// height: auto;
				padding: 10px;
				// margin: 5px;
				top: auto;
				width: auto;
				border-radius: 0;
			}
			&:before {
				content: "How does it work?";
				position: absolute;
				top: -25px;
				padding: 10px 20px;
				background: #323854;
				color: white;
				text-transform: uppercase;
				border-radius: 4px;
				@include center;

				@include respondToBelow(sm) {
					padding: 20px 0px;
					width: 100%;
					border-radius: 0;
					top: -60px;
				}
			}
			position: absolute;
			// top: 30%;
			padding: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 60%;
			height: auto;
			background: #ffffff9c;
			box-shadow: 0px 11.1107px 74.071px rgba(65, 158, 211, 0.17);
			border-radius: 8px;

			.illustrator {
				@include respondToBelow(sm) {
					width: auto;
				}
				width: 40%;
			}
			.content {
				@include respondToBelow(sm) {
					width: 100%;
				}
				// width: 60%;
				display: flex;
				flex-direction: column;
				font-size: 18px;
				justify-content: space-between;
				margin-left: 10px;

				div {
					&:first-of-type {
						line-height: 153.19%;
						letter-spacing: 0.035em;
						color: $color;
						@include respondToBelow(sm) {
							font-size: 16px;
							margin-top: 20px;
						}
					}

					&:last-of-type {
						@include respondToBelow(sm) {
							width: auto;
						}
						width: 100%;
						padding: 10px;
						@include center;
						background: #323854;
						box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
						border-radius: 8px;
						color: white;
						font-size: 18px;
						margin-top: 20px;
						span {
							+ span {
								margin-left: 10px;
							}
						}
					}
				}
			}
		}
	}

	.section3 {
		position: relative;
		color: #003e61;
		letter-spacing: 0.035em;
		height: 800px;
		// width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 30px;
		background-image: url(/static/media/waves.cec7e17a.svg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 0% -600%;

		@include respondToBelow(sm) {
			height: auto;
			// width: 100%;
		}
		.section4img {
			position: absolute;
			bottom: 0;
			right: 0;
			// z-index: 1;
			width: 100%;
		}

		.main {
			width: 90%;
			@extend %titlecontainer;
			@include respondToBelow(sm) {
				width: 100%;
			}
			.feature-container {
				@include respondToBelow(sm) {
					flex-direction: column;
					align-items: center;
				}
				display: flex;
				width: 100%;
				justify-content: space-around;
				margin-top: 50px;
				.card {
					z-index: 2;
					@include respondToBelow(sm) {
						+ .card {
							margin-top: 20px;
						}
					}

					//  remove the below when testimonial is added
					&:last-of-type {
						margin-bottom: 150px;
					}
					display: flex;
					flex-direction: column;
					padding: 20px;
					width: 300px;
					letter-spacing: 0.035em;
					line-height: 120%;
					height: 300px;
					background: #ffffff;
					box-shadow: 0px 4px 60px rgba(72, 72, 72, 0.15);
					border-radius: 8px;
					.card-section1 {
						opacity: 0.8;
						border-radius: 5px;
						display: flex;
						flex-direction: row;
						width: 100%;
						align-items: center;
						color: white;
						font-weight: bold;
						height: 50px;
						font-size: 16px;
						padding: 10px 0px;
						justify-content: space-between;

						div {
							&:first-of-type {
								margin-left: 10px;
							}

							&:last-of-type {
								margin-right: 10px;
							}
						}
					}

					.card-section2 {
						margin-top: 20px;
						font-size: 16px;
					}
				}
			}
		}
	}

	.section4 {
		color: #003e61;
		background: #ecf5fb;
		letter-spacing: 0.035em;
		height: 1000px;

		position: relative;
		.section4img {
			position: absolute;
			bottom: 0;
			right: 0;
		}
		@include center;
		padding: 30px;
		@include respondToBelow(sm) {
			padding: 60px 0 200px 0;
			height: auto;
			// margin-bottom: 180px;
		}
		.main {
			@extend %titlecontainer;
			width: 90%;
		}
	}

	.section5 {
		height: 300px;
		position: relative;
		width: 100%;
		background-color: #ffffff;
		@include center;
		flex-direction: column;
		justify-content: space-evenly;
		color: #323854;
		.semi {
			@include center;
			.button {
				a {
					text-decoration: none;
					color: white;
				}
				font-size: 20px;
				@extend %btn;
				background: linear-gradient(270deg, #f48508 0%, #ff9900 100%);
				box-shadow: 0px 30px 45px rgba(254, 152, 0, 0.2);
				@include center;
				span {
					+ span {
						margin-left: 10px;
					}
				}
				padding: 10px 25px;
				position: absolute;
				top: -30px;
			}
			position: absolute;
			top: -100px;
			left: 0;
			right: 0;
			width: 100%;
			height: 100px;
			z-index: 10;
			background-image: url("../../images/wave_top.svg");
			background-repeat: no-repeat;
			background-position: center;
			-moz-background-size: cover; /* Firefox 3.6 */
			background-size: cover;
			background-position: center; /* Internet Explorer 7/8 */
		}
		.text {
			font-size: 28px;
			font-weight: bold;
			position: relative;
			color: #323854c0;
			@include respondToBelow(sm) {
				font-size: 20px;
			}
			@include center;
			&:before {
				content: "";
				top: 45px;
				position: absolute;
				width: 120px;
				height: 4px;
				border-radius: 20px;
				background-color: #323854c0;
			}
		}

		.input-container {
			background: rgba(244, 133, 8, 0.1);
			box-shadow: 0px 4px 60px rgba(255, 255, 255, 0.1);
			border-radius: 50px;
			width: 60%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px;
			font-size: 16px;
			@include respondToBelow(xs) {
				font-size: 12px;
			}
			@include respondToBelow(sm) {
				width: auto;
			}
			button {
				@extend %btn;
				padding: 10px 20px;
				border-radius: 50px;
			}
			.input {
				input {
					background: transparent;
					&::placeholder {
						font-size: 16px;
						color: #f48508;
						width: 120%;
					}
					padding: 10px;
					border: none;
				}
			}
		}
	}

	.copyright {
		font-size: 16px;
	}
}

@keyframes slideDown {
	0% {
		transform: translateZ(0);
	}
	100% {
		transform: translate3d(0, -300px, 0);
	}
}
@keyframes slideUp {
	0% {
		opacity: 0;
		transform: scale(0.5) translate3d(0, 0px, 0);
	}
	100% {
		opacity: 1;
		transform: scale(1) translateZ(0);
	}
}

.navbar--down {
	animation: slideDown 0.3s ease-in forwards;
}

.navbar--up {
	animation: slideUp 0.3s ease-out backwards;
}

.download_btn {
	@extend %btn;
	padding: 10px 25px;

	@include respondToBelow(sm) {
		padding: 10px 20px;
		font-size: 18px;
	}

	span {
		margin-top: 10px;
		margin-left: 10px;
		// margin-top: 8px;
	}
}

.bulb_container {
	@extend %btn;
	padding: 10px;
	display: flex;
	align-items: center;
	span {
		&:last-of-type {
			// height: 60px;
			img {
				width: 25px;
			}
		}
	}
}

.play {
	@extend %btn;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 9px;
	background: transparent;
	border: 2px solid white;
	span {
		&:first-of-type {
			margin-right: 5px;
		}
	}
	@include respondToBelow(sm) {
		// padding: 8px 20px;
		font-size: 18px;
	}
}

.video {
	position: relative;
	// padding-bottom: 56.25%; /* 16:9 */
	height: 100%;
	background-color: transparent;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
